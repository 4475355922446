import "./styles.css";
import { Modal, Typography } from "antd";
import silkDashboardPng from "./silkDashboard.png";

export default function App() {
  return (
    <div className="App">
      <img src={silkDashboardPng} style={{ height: "100vh" }} />
      <Modal
        open={true}
        onCancel={() => {}}
        footer={null}
        closeIcon={null}
      >
        <Typography.Title>We&apos;ve moved domains!</Typography.Title>
        <p>
          We are now hosted on two regions.
          <ul>
            <li>{redirectComponent("https://us1.app.silk.security")}</li>
            <li>{redirectComponent("https://eu1.app.silk.security")}</li>
          </ul>
          Update your bookmarks as this current domain will soon go away.
        </p>
      </Modal>
    </div>
  );
}

function redirectComponent(redirectUrl) {
  return <a href={redirectUrl}>{redirectUrl}</a>;
}
